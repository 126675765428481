import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import NoSsr from "@mui/material/NoSsr";

import Main from "../../layouts/Main";
import Container from "../../components/Container";
import { Welcome } from "./components";
import b2sBackground from "./components/ContainerBigLeft/b2s_background.webp";
import subgrowLogo from "./components/ContainerBigLeft/subgrow_logo.svg";
import ContainerBigLeft from "./components/ContainerBigLeft";
import Work from "../Services/components/Work";
import { getI18n, Trans, useTranslation } from "react-i18next";

const B2S_SITE = new Map([
  ["en", "https://subgrow.net/"],
  ["ja", "https://subgrow.jp/"],
]);

const Products = (): JSX.Element => {
  const { t } = useTranslation();
  const i18n = getI18n();

  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll(".jarallax");
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      // @ts-ignore
      const { jarallax } = await import("jarallax");
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  const scrollTo = (id: string): void => {
    setTimeout(() => {
      const element: HTMLElement | null = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: "smooth" });
    });
  };

  const theme = useTheme();

  const styles = (bgImage: string) =>
    ({
      position: "absolute",
      objectFit: "cover",
      /* support for plugin https://github.com/bfred-it/object-fit-images */
      fontFamily: "object-fit: cover;",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: -1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundImage: `url(${bgImage})`,
      filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
    } as const);

  return (
    <Main>
      <Box
        minHeight={"100vh"}
        display={"flex"}
        alignItems={"center"}
        bgcolor={"alternate.main"}
        marginTop={-13}
        paddingTop={13}
      >
        <Container>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Welcome />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginTop={3}
              onClick={() => scrollTo("agency__portfolio-item--js-scroll")}
              sx={{ cursor: "pointer" }}
            >
              <Trans>Meet our products</Trans>
              <NoSsr>
                <Box
                  component={"svg"}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  width={{ xs: 30, sm: 40 }}
                  height={{ xs: 30, sm: 40 }}
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </Box>
              </NoSsr>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        className={"jarallax"}
        data-jarallax
        data-speed="0.1"
        position={"relative"}
        minHeight={"100vh"}
        display={"flex"}
        alignItems={"center"}
        id="agency__portfolio-item--js-scroll"
      >
        <Box
          className={"jarallax-img"}
          sx={{
            ...styles(b2sBackground),
            backgroundPosition: { xs: "right -130px center", sm: "right" },
            boxShadow: {
              xs: "inset 0px 0px 428px 428px #0000004a",
              md: "none",
            },
          }}
        />
        <Container>
          <ContainerBigLeft
            title={<Trans> subgrow</Trans>}
            subtitle={<Trans>Helps to return your subscribers</Trans>}
            icon={subgrowLogo}
            url={B2S_SITE.get(i18n.language) || ""}
            glowOnMobile
          />
        </Container>
      </Box>
      <Box bgcolor={"alternate.main"} pt={6} pb={6}>
        <Container>
          <Work />
        </Container>
      </Box>
    </Main>
  );
};

export default Products;
