/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import Image from "../../../../components/Image";
import CallMadeIcon from "@mui/icons-material/CallMade";

const ContainerBigLeft = ({
  title,
  subtitle,
  icon,
  url,
  invertColors = false,
  rightAlignedOnMobile = false,
  glowOnMobile = false, // Adds text glowing on mobile to improve back text on dark image readability
}: {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  icon: any;
  url: string;
  invertColors?: boolean;
  rightAlignedOnMobile?: boolean;
  glowOnMobile?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "48rem",
        alignItems: rightAlignedOnMobile
          ? {
              xs: "flex-end",
              sm: "flex-start",
            }
          : "flex-start",
        textAlign: rightAlignedOnMobile
          ? {
              xs: "right",
              sm: "left",
            }
          : "left",
        textShadow: {
          xs: glowOnMobile ? "0px 0px 10px black" : "none",
          md: "none",
        },
      }}
    >
      <Avatar
        sx={{
          display: { xs: "block", sm: "none" },
          width: 88,
          height: 88,
          mb: "1.2rem",
          borderRadius: "0",
        }}
        src={icon}
      />
      <Typography
        variant="h1"
        sx={{
          fontWeight: 700,
          color: invertColors ? "common.black" : "common.white",
          right: "6rem",
          lineHeight: "6rem",
          // marginBottom: 2,
        }}
      >
        <Box
          display="flex"
          alignItems={"center"}
          // mt={{ xs: "1rem", xl: 0 }}
          sx={{}}
        >
          <Avatar
            sx={{
              display: { xs: "none", sm: "block" },
              width: 88,
              height: 88,
              mr: "2rem",
              borderRadius: "0",
            }}
            src={icon}
          />
          {title}
        </Box>
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontWeight: 400,
          color: invertColors ? "common.black" : "common.white",
          marginBottom: 2,
          pl: { xs: 0, sm: "7.6rem" },
        }}
      >
        {subtitle}
      </Typography>
      <Button
        variant="outlined"
        target="_blank"
        href={url}
        size="large"
        sx={{
          color: invertColors ? "common.black" : "common.white",
          borderColor: invertColors ? "#00000030" : "common.white",
          fontSize: "1rem",
          mt: "1rem",
          ml: { xs: 0, sm: "7.6rem" },
        }}
        endIcon={
          <svg
            width="14"
            height="15"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 0.549927C0.902344 0.549927 0 1.45227 0 2.54993V10.5499C0 11.6476 0.902344 12.5499 2 12.5499H10C11.0977 12.5499 12 11.6476 12 10.5499V2.54993C12 1.45227 11.0977 0.549927 10 0.549927H2ZM2 1.54993H10C10.5586 1.54993 11 1.99133 11 2.54993V10.5499C11 11.1085 10.5586 11.5499 10 11.5499H2C1.44141 11.5499 1 11.1085 1 10.5499V2.54993C1 1.99133 1.44141 1.54993 2 1.54993ZM5 2.54993V3.54993H8.29297L4.02344 7.82337L4.72656 8.52649L9 4.25696V7.54993H10V2.54993H5Z"
              fill="white"
            />
          </svg>
        }
      >
        <Trans>Visit website</Trans>
      </Button>
      {/*<Typography*/}
      {/*  variant="h1"*/}
      {/*  align={'center'}*/}
      {/*  gutterBottom*/}
      {/*  sx={{*/}
      {/*    fontWeight: 900,*/}
      {/*    color: theme.palette.common.white,*/}
      {/*    textTransform: 'uppercase',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Nike*/}
      {/*</Typography>*/}
      {/*<Typography*/}
      {/*  variant="h6"*/}
      {/*  component="p"*/}
      {/*  color="text.primary"*/}
      {/*  align={'center'}*/}
      {/*  sx={{*/}
      {/*    color: theme.palette.common.white,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Just do it*/}
      {/*</Typography>*/}
    </Box>
  );
};

export default ContainerBigLeft;
