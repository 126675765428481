/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Trans, useTranslation } from "react-i18next";

const Welcome = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const GridItemHeadlineBlock = () => (
    <Box>
      <Typography
        variant="h3"
        align={"center"}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        <Trans i18nKey="WE_ALSO_DEVELOP_OWN_PRODUCTS">
          We also develop our
          <br />
          <span style={{ marginTop: 0, color: "#4743FF" }}>own products</span>
        </Trans>
      </Typography>
      <Typography
        variant="h5"
        align={"center"}
        gutterBottom
        sx={{ mt: "2rem" }}
      >
        <Trans i18nKey="EVALUATED_BY_TOKYO_METROPOLITAN">
          Our application automatic sales support system business (subgrow)<br/>was
          evaluated by Tokyo Metropolitan Small Business Promotion Agency<br/>as
          having business potential
        </Trans>
      </Typography>
    </Box>
  );

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
          >
            <GridItemHeadlineBlock />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Welcome;
